@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');


html {
  overflow-y:scroll;
}

body {
  overflow: visible;
  font-family: "Zain"!important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* 
@media (min-width: 991.98px) {
  main {
    padding-inline-start: 240px;
  }
} */

.navbar {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05);
  padding-top: 0.5625rem;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 58px 0 0;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .sidebar {
    padding: 116px 0 0;
  }

  .mainContent {
    margin-top: 116px;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.truncate {
  width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-box {
  float: inline-end;
}

.lang-selector {
  border: dotted 1px black !important;
  margin-inline-start: 5px !important;
  margin-inline-end: 5px !important;
}

.loading-bg {
  width: 100%;
  height: 150px;
}

@media screen and (min-width: 1024px) {
  .outer-container {
      max-width: 460px;
  }
}

.ms-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 32px;
  white-space: nowrap;
  cursor: pointer;
  color: #1bbae1;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  transition: .1s;
  font-weight: 500;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #dce1e7;
  border-color: #8dddf0;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 4px;
}

.ms-button.is-text {
  padding-left: 0;
  padding-right: 0;
  min-width: auto;
  color: #1bbae1;
  border: 0 solid transparent;
  background-color: transparent;
}

.shortcut-button.ms-button.is-text, .shortcut-button:hover {
  background-color: #f8f9fb;
  border-inline-end: 1px solid #dce1e7;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 120px;
}

.ms-range-editor--large.ms-input__wrapper {
  height: 40px;
}

.ms-icon {
  height: 1em;
  width: 1em;
  line-height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  fill: currentColor;
  color: inherit;
  font-size: inherit;
}

.ms-date-editor .ms-range__icon {
  height: inherit;
  font-size: 14px;
  color: #6d7478;
  float: inline-start;
}

.ms-range-editor.ms-date-editor--daterange .ms-icon.ms-input__icon.ms-range__icon {
  margin-inline-start: 5px;
}

.ms-date-editor .ms-range-separator {
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 5px;
  margin: 0;
  font-size: 14px;
  word-break: keep-all;
  color: green;
}

.ms-range-editor--large .ms-range-separator {
  line-height: 40px;
  font-size: 14px;
}

.ms-date-editor .ms-range-input {
  appearance: none;
  border: none;
  outline: none;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  width: 39%;
  text-align: center;
  font-size: 16px;
  color: #6d7478;
  background-color: transparent;
}

.ms-container {
  display: flex;
  flex-direction: row;
  max-width: 460px;
  border: 1px solid #dce1e7;
  border-radius: 4px;
}

.ms-button--large {
  height: 40px;
  padding: 12px 19px;
  font-size: 14px;
  border-radius: 4px;
}

.ms-range-editor--large .ms-range-input {
  height: 38px;
  line-height: 38px;
  font-size: 14px;
}

.ms-range-editor.ms-date-editor--daterange .ms-range-input {
  color: #3b3b3b;
}

.ms-button>span {
  display: inline-flex;
  align-items: center;
}

.ms-date-editor .ms-range__close-icon--hidden {
  opacity: 0;
  visibility: hidden;
}

.ms-range-editor.ms-date-editor--daterange input.ms-range-input {
    width: 40%;
}

.ms-date-editor.ms-input, .ms-date-editor.ms-input__wrapper {
  width: 350px;
  height: var(input---ms-height, 32px);
}

.ms-range-editor.ms-input__wrapper {
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
}

.ms-range-editor.is-active, .ms-range-editor.is-active:hover {
  box-shadow: 0 0 0 1px #1bbae1 inset;
}

.so-input {
  position: relative;
  font-size: 14px;
  display: inline-flex;
  width: 100%;
  line-height: 40px;
  box-sizing: border-box;
  vertical-align: middle;
}

.so-input--large {
  font-size: 14px;
}

.so-input__wrapper {
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 1px 11px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  cursor: text;
  transition: box-shadow .1s cubic-bezier(.645, .045, .355, 1);
  transform: translateZ(0);
  box-shadow: 0 0 0 1px #dce1e7 inset;
}


.so-input--large .so-input__wrapper {
  padding: 2px 15px;
}

.so-input .so-input__wrapper {
  width: 100%;
}

.search {
  display: flex;
}

@media screen and (min-width: 1024px) {
  .search {
    max-width: 300px;
  }
}


.so-input__prefix {
  display: inline-flex;
  white-space: nowrap;
  flex-shrink: 0;
  flex-wrap: nowrap;
  height: 100%;
  text-align: center;
  color: var(--so-input-icon-color, var(--so-text-color-placeholder));
  transition: all var(--so-transition-duration);
  pointer-events: none;
}

.so-input__prefix-inner {
  pointer-events: all;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.so-input__inner {
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  color: #3b3b3b, #6d7478;
  font-size: inherit;
  height: 38px;
  line-height: 38px;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  box-sizing: border-box;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.filters-container {
  display: flex;
  gap: 10px;
  width: 100%;
}

@media screen and (min-width: 1335px) {
  .filters-container {
    display: contents;
  }
}

.flex-grow {
  flex-grow: 1;
  margin-top: -10px;
  margin-left: -10px;
}

.so-input__prefix-inner>:last-child {
  margin-inline-end: 8px;
}

.menu-item {
  font-family: Zain!important;
}

/* .visit-offcanvas { */
  /* --bs-offcanvas-width: 450px!important; */
/* } */

.data-column {
  align-content: start!important;
  text-align: start!important;
}